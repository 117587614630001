<template>
  <div>
    <Navbar />
    <div class="mt-3 mb-5">
      <div v-if="total === 0" class="centeringSearch">
        <div style="width: 100%">
          <form @submit.prevent="myFunction" class="p-0">
            <input
              class="p-0"
              v-model="searchKey"
              type="text"
              :placeholder="$t('front.search')"
            />
          </form>
          <div class="resultCont">
            <p class="result mb-0">
              {{ $t("front.searchResult") }} <span>{{ total }}</span>
            </p>
            <h5 style="text-transform: uppercase" class="mtavruliBOLD mt-2">
              {{ $t("front.didnotFind") }}
            </h5>
            <!-- <p>{{ searchKey }}</p> -->
          </div>
        </div>
      </div>
      <div v-else class="row centeringSearch">
        <div style="width: 100%">
          <form @submit.prevent="myFunction" class="p-0">
            <input
              class="p-0"
              v-model="searchKey"
              type="text"
              :placeholder="$t('front.search')"
            />
          </form>
          <div class="resultCont">
            <p class="result">{{ $t("front.searchResult") }} {{ total }}</p>
          </div>

          <b-skeleton-img
            v-if="isLoading"
            no-aspect
            height="45rem"
            width="100%"
            style="max-width: '100%"
          ></b-skeleton-img>
          <div v-else class="searchContainer">
            <div class="col pl-0 pr-0" v-for="item in items" :key="item._id">
              <div>
                <div class="card bg-transparent mt-3 p-0">
                  <div class="card-body p-0">
                    <h5
                      v-if="lang == 'ka'"
                      v-html="item.titleKA"
                      class="card-title mb-0 cmp-searchresults__link mtavruli"
                    ></h5>
                    <h5
                      v-html="item.titleEN"
                      v-else
                      class="card-title mb-0 cmp-searchresults__link"
                    ></h5>

                    <h5
                      v-if="lang == 'ka'"
                      v-html="item.fullNameKA"
                      class="cmp-searchresults__link card-title mb-0 mtavruli"
                    ></h5>
                    <h5
                      v-html="item.fullNameEN"
                      v-else
                      class="card-title cmp-searchresults__link mb-0 mtavruli"
                    ></h5>
                    <h5
                      v-if="lang == 'ka'"
                      v-html="item.positionKA"
                      class="card-title mb-0"
                    ></h5>

                    <h5
                      v-html="item.positionEN"
                      style="font-size: 14px"
                      v-else
                      class="card-title mb-0"
                    ></h5>

                    <p
                      v-if="lang == 'ka'"
                      v-html="item.subTitleKA"
                      class="card-text mb-0 mb-0"
                    ></p>

                    <p
                      v-else
                      class="card-text mb-0"
                      v-html="item.subTitleEN"
                    ></p>
                    <h5
                      v-if="lang == 'ka'"
                      v-html="item.categorKA"
                      class="cmp-searchresults__link card-text mb-0"
                    >
                      {{ item.categorKA }}
                    </h5>
                    <h5
                      v-else
                      v-html="item.categorEN"
                      class="cmp-searchresults__link card-text mb-0"
                    >
                      {{ item.categorEN }}
                    </h5>

                    <p
                      v-if="lang == 'ka'"
                      class="descriptionFormatong mb-0"
                      v-html="removeHTML(item.descriptionKA)"
                    ></p>
                    <p
                      v-else
                      class="descriptionFormatong mb-0"
                      v-html="removeHTML(item.descriptionEN)"
                    ></p>
                    <p
                      v-if="lang == 'ka'"
                      class="descriptionFormatong mb-0"
                      v-html="removeHTML(item.productDescriptionKA)"
                    ></p>
                    <p
                      v-else
                      class="descriptionFormatong mb-0"
                      v-html="removeHTML(item.productDescriptionEN)"
                    ></p>

                    <h5
                      class="seeMore mtavruli pt-0 mb-0"
                      style="vertical-align: text-top"
                    >
                      <router-link
                        class=""
                        style="text-decoration: none; padding-bottom: 20px"
                        :to="`/${item.route_category}/${item.slug}/${
                          item.route_category == 'products'
                            ? 'medicamentsDetail'
                            : ''
                        }`"
                      >
                        <span
                          class="btnArchive cmp-searchresults__link"
                          style="font-size: 15px; vertical-align: text-top"
                        >
                          {{ $t("front.more") }}</span
                        >
                      </router-link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import Navbar from "../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../components/Footer/newFooter/Footer.vue";

export default {
  components: { Navbar, Footer },
  data() {
    return {
      isLoading: true,
      lang: "ka",
      searchKey: "",
      items: [],
      category: "",
      total: "",
      _id: 0,
      inputValue: this.$route.params.input,
    };
  },
  watch: {
    inputValue: function (newValue) {
      console.log(newValue);
    },
  },

  mounted() {
    axios
      .get(`${env.host}/get/search/${this.$route.params.input}`)
      .then((result) => {
        this.items = result.data.item;
        // this.catgeory = result.data.item[0].category;
        this.total = result.data.item.length;
        this._id = result.data.item._id;
        this.isLoading = false;
        // console.log(result.data.item[0].category + "გამოიტაანა");
        // console.log("gamoitana" + result.data.item.length);
        // console.log("gamovida" + result.data.item[0].route_category);
      });
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
  methods: {
    myFunction() {
      window.location.href = `/searchPage/${this.searchKey}`;
    },

    removeHTML: function (string) {
      return string?.replace(/<\/?[^>]+>/gi, " ");
    },
    subStringText: function (string) {
      return (
        string.substring(3, 220).split("&nbsp;").join("").split("·&").join("") +
        "..."
      );
    },
  },
};
</script>

<style scoped>
h5 >>> * {
  margin-bottom: 0px !important;
}
p >>> * {
  margin-bottom: 0px !important;
}
.cmp-searchresults__link {
  color: #071d49;
  text-decoration: none;
  background: linear-gradient(180deg, #fff 50%, rgb(196, 194, 194) 0);
  background-size: 2px 2px;
  background-repeat: repeat-x;
  background-position: 0 100%;
  transition: color 0.1s ease;

  font-size: 1pc;
  font-size: 1.6rem;
  line-height: 1.5;
}
.searchContainer {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  min-height: calc(100vh - 240px);
  overflow-y: scroll;
  max-height: 300px !important;
  /* border: 2px solid red; */
}
/* width */
::-webkit-scrollbar {
  width: 4px;
}
p {
  margin-bottom: 0px !important;
}

/* Track */
::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e549374;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}
/* 
.searchTable {
  width: 50%;
} */

.descriptionFormatong {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
form {
  margin: auto;
  display: flex;
  justify-content: center;
}
input {
  width: 50%;

  padding-left: 5px;
  /* border-radius: 5px; */
  text-align: center;
  outline: none;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid grey;
}

.centeringSearch {
  margin: auto;
  width: 80%;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  min-height: calc(100vh - 240px);
}

.card {
  border: none;
  border-radius: 0px;
  width: 100%;
  margin: auto;
  /* border-bottom: 1px solid #7f5496; */
}
.card-title,
p,
h4,
h5 {
  color: #4d4d4cd2;
}
h5 {
  font-size: 18px !important;
}
.btnArchive {
  border: none;
  color: #7e5493;
  font-family: mtavruli;
  text-transform: uppercase;
  /* border-bottom: 1.5px solid #c6bec9; */
}
.seeMore {
  font-size: 14px;
}

.resultCont {
  /* border: 2px solid red; */
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  flex-direction: column;
}

@media all and (max-width: 1500px) {
  .searchContainer {
    /* padding-left: 40px; */
    padding-right: 20px;
  }
}
</style>
